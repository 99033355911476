// src/UserRegistration.js
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const UserRegistration = ({ onRegistration, userRole }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [selectedRole, setSelectedRole] = useState('User');

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    
    const lowercaseUsername = e.target.value.toLowerCase();
    setEmail(lowercaseUsername);
    
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://82.180.163.36:5000/api/users', {
        Name: name,
        Email: email,
        Password: password, 
        Role: selectedRole, 
      });

      const data = response.data;
      console.log(data); // Check the response from the server

      if (data) {
        // Call the onRegistration function passed as a prop from the parent component (App.js)
        onRegistration(data.message);
        toast.success('Registration successfully!');// Assuming the server returns a success message after registration
      } else {
        // Handle registration failurec
        toast.error('Registration failed:', data.message);
        console.log('Registration failed:', data.message);
      }

      // Reset the form after handling the data
      setName('');
      setEmail('');
      setPassword('');
      
      setSelectedRole('User');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return userRole === 'Admin' ?(
    <div className="registration-container">
    <form onSubmit={handleSubmit}>
      <div className="form-row">
        <label>Name:</label>
        <input type="text" value={name} onChange={handleNameChange} required />
      </div>
      <div className="form-row">
        <label>Email:</label>
        <input type="email" value={email} onChange={handleEmailChange} required />
      </div>
      <div className="form-row">
        <label>Password:</label>
        <input type="password" value={password} onChange={handlePasswordChange} required />
      </div>
      <div className="form-row">
        <label>Role:</label>
        <select value={selectedRole} onChange={handleRoleChange}>
          <option value="Admin">Admin</option>
          <option value="User">User</option>
        </select>
      </div>
      <button type="submit">Register</button>
    </form>
  </div>
  ): null;
};

export default UserRegistration;
