import React ,{useState}  from "react";
import "./print.css"; // Import the print CSS file for custom styling
import numberToWords from 'number-to-words';
import numberToArWords from 'number-to-arabic-words';
import logo from '../image/test.jpg';
import nlp from 'compromise';
function reverseString(num) {
  const reversedNum = Number(String(num).split('').reverse().join(''));
  return reversedNum;
}
function swapLastTwoWords(text) {
  const words = text.split(" ");
  
  if (words.length < 2) {
    return text; // Return the original text if there are less than two words
  }
  
  const lastWord = words.pop(); // Remove the last word
  const secondLastWord = words.pop(); // Remove the second-to-last word
  
  words.push(lastWord); // Add the last word as the second-to-last word
  words.push(secondLastWord); // Add the second-to-last word as the last word
  
  return words.join(" ");
}

function convertNumberToArabicWords(number) {
  const units = ["", "ألف", "مليون", "مليار", "تريليون", "كوادريليون", "كوينتليون"];
  const words = ["صفر", "واحد", "اثنان", "ثلاثة", "أربعة", "خمسة", "ستة", "سبعة", "ثمانية", "تسعة"];
  const handard=["","مائة", "مائتين", "ثلاثمائه", "أربعمائة", "خمسمائة", "ستمائة", "سبعمائة", "ثمانمائة", "تسعمائة"]
  const tensWords = ["", "", "عشرون", "ثلاثون", "أربعون", "خمسون", "ستون", "سبعون", "ثمانون", "تسعون"];
  const teensWords = ["عشرة", "أحد عشر", "اثنا عشر", "ثلاثة عشر", "أربعة عشر", "خمسة عشر", "ستة عشر", "سبعة عشر", "ثمانية عشر", "تسعة عشر"];


  if (number < 10) {
    return words[number];
  } else if (number < 20) {
    return teensWords[number - 10];
  } else if (number < 100) {
   const re= reverseString(number);
    return tensWords[Math.floor(number / 10)] + (number % 10 !== 0 ? " " + words[number % 10] : "");
  } else if (number < 1000) {
    return handard[Math.floor(number / 100)] + " " + (number % 100 !== 0 ? " " + convertNumberToArabicWords(number % 100) : "");
  } else {
    let unitIndex = 0;
    let result = "";

    while (number > 0) {
      const triplet = number % 1000;

      if (triplet > 0) {
        const tripletWords = convertNumberToArabicWords(triplet);
        result = tripletWords + (unitIndex > 0 ? " " + units[unitIndex] : "") + (result ? " ," + result : "");
      }

      number = Math.floor(number / 1000);
      unitIndex++;
    }

    return result;
  }
}

function convertNumberToWords(number) {
  const units = ["", "Thousand", "Million", "Billion", "Trillion", "Quadrillion", "Quintillion"];
  const words = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
  const tensWords = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  const teensWords = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];

  if (number < 10) {
    return words[number];
  } else if (number < 20) {
    return teensWords[number - 10];
  } else if (number < 100) {
    return tensWords[Math.floor(number / 10)] + (number % 10 !== 0 ? " " + words[number % 10] : "");
  } else if (number < 1000) {
    return words[Math.floor(number / 100)] + " Hundred" + (number % 100 !== 0 ? " " + convertNumberToWords(number % 100) : "");
  } else {
    let unitIndex = 0;
    let result = "";

    while (number > 0) {
      const triplet = number % 1000;

      if (triplet > 0) {
        const tripletWords = convertNumberToWords(triplet);
        result = tripletWords + (unitIndex > 0 ? " " + units[unitIndex] : "") + (result ? ", " + result : "");
      }

      number = Math.floor(number / 1000);
      unitIndex++;
    }

    return result;
  }
}
function convertDecimalToWords(decimal) {

  // Limit the decimal part to two digits
  const roundedDecimal = Math.round(decimal * 100);
  if (roundedDecimal === 0) {
    return "";
  } else {
    return "and " + convertNumberToWords(roundedDecimal) + " Dirhams";
  }
}
function convertDecimalToArabicWords(decimal) {
  const roundedDecimal = Math.round(decimal * 100);
  if (roundedDecimal === 0) {
    return "";
  } else {
    const decimalWords = convertNumberToArabicWords(roundedDecimal); // Use the Arabic
    return "و " + decimalWords + " درهمًا";
  }
}

const InvoicePrint = (props) => {
  const currentDate = new Date().toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  const totalValue = props.props?.Total ?? 0;
  const dateObject = new Date(props.props?.date);

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");
  
  const formattedDate = `${year}-${month}-${day}`;

  const formattedTotal = new Intl.NumberFormat('en-QA', {
    style: 'currency',
    currency: 'QAR',
  }).format(totalValue);
  
  
  
  const totalWordsEn = convertNumberToWords(Math.floor(totalValue));
  
  let totalWordsAr = convertNumberToArabicWords(Math.floor(totalValue));
  const swappedText = swapLastTwoWords(totalWordsAr);
  console.log(swappedText);
  totalWordsAr=swappedText;
  const decimalPart = totalValue - Math.floor(totalValue);
  const decimalWordsEn = convertDecimalToWords(decimalPart);
  const decimalWordsar = convertDecimalToArabicWords(decimalPart);


  return (
    <>
      <div className="print-main" ref={props.forwardedRef}>
        <div className="item">
          <div className="print-container">
            <div className="top-border" style={{ paddingLeft: "12px" }}>
              {/* <img src="" style={{ width: '50%', height: '32%' }}></img> */}
              <h10></h10>
              <br></br>
              <h10></h10> <br></br>
              <h10></h10> <br></br>
              <h10></h10><br></br>
              <h10></h10>
              <br></br>
            </div>
            <div
              className="left-content"
              style={{ textAlign: "right", paddingRight: "12px" }}
            >
              <h1></h1>
              <h10></h10> <br></br>
              <h10></h10>
              <br></br>
              <h10></h10>
              <br></br>
              <h10>
              </h10>
              <br></br>
              <h1></h1>
              <br></br>
            </div>
          </div>
          <hr /></div>
        <div class="credeit-invoice-div">
          <h3>INVOICE فاتورة</h3>
        </div>
        <div>
          <label style={{paddingLeft:'3%'}}>Bill To : {props.props?.customerName}</label>
          <h5></h5>
        </div>
        <div style={{ paddingLeft:'50%' }}>
          <div></div>
          <div className="table-container" >
            <table className="items-table" >
              <thead>
                <tr>
                  <th>L.P.O</th>
                  <th>REP</th>
                  <th>Date التاريخ</th>
                  <th>Invoice # فاتورة</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td>IR</td>
                  <td>{formattedDate}</td>
                  <td>{props.props?.inID}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="print-item-table">
          <div>
            <table>
              <thead>
                <tr>
                  <th>Item غرض</th>
                  <th>Description وصف</th>
                  <th>Class</th>
                  <th>Qty كمية</th>
                  <th>Rate معدل</th>
                  <th>Amount كمية</th>
                </tr>
              </thead>
              <tbody>
                {props.props?.orderItems?.filter(item => item.InvoiceId.includes(props.props._id)).map((item, i) => {

                  return (
                    <tr>
                      <td>{item.Item}</td>
                      <td>{item.Description + "              " + item.DetailsDescription}</td>
                      <td>{item.Classs}</td>
                      <td>{item.Quantity}</td>
                      <td>{item.Rate?.toLocaleString()}</td>
                      <td>
                        { parseFloat((parseFloat(item.Quantity) * parseFloat(item.Rate))?.toLocaleString()).toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="5" style={{ textAlign: 'left', verticalAlign: 'top',marginTop: '0' }}>
                  <h4 style={{ fontStyle: 'italic', marginBottom: '0',marginTop: '0' }}>Total (المبلغ الإجمالي) :</h4>

                  <br></br>
                    <span lang="en" style={{ textTransform: 'capitalize' }}>  Qatari Riyals {totalWordsEn} {decimalWordsEn} Only</span>
                    <br></br>
                   <span lang="ar" style={{ textTransform: 'capitalize' }} >   ريال قطرى  {totalWordsAr} {decimalWordsar} فقط  </span>
                  </td>
                  <td>
                  <h5 style={{ marginTop: '0', textTransform: 'capitalize' }}>{formattedTotal}</h5>
       
                  </td>

                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div>



        </div>
      </div>
    </>
  );
};

// Wrap the component using React.forwardRef to forward the ref to the underlying DOM element
const ForwardedInvoicePrint = React.forwardRef((props, ref) => (
  <InvoicePrint forwardedRef={ref} {...props} />
));

export default ForwardedInvoicePrint;
