import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Invoice from "./Invoice";
import "./InvoiceList.css";
import OrderItem from './OrderItem';
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft, faChevronRight, faPrint } from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";
import CustomerForm from "./CustomerForm";
import AddItemForm from "./AddItemForm";
import PrintableComponent from "./PrintableComponent"; // Import the InvoicePrint component
import ReactToPrint from "react-to-print";


const itemsPerPage = 5;
const apiUrl = "http://82.180.163.36:5000/api/invoices";
const apiUrlOrderItems = "http://82.180.163.36:5000/api/orderItems";
const apiUrlItems = 'http://82.180.163.36:5000/api/items/search';
const InvoiceList = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1); // Start from page 1
  const itemsPerPage = 10;
  const [items, setItems] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const invoiceRef = React.createRef();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [Item, setItem] = useState("");
  const [Description, setDescription] = useState("");
  const [inID, setinID] = useState("");
  const [DateTime, setDateTime] = useState(new Date());

  const [Classs, setClasss] = useState("");
  const [Rate, setRate] = useState("");
  const [DetailsDescription, setDetailDescr] = useState("");
  const [Quantity, setQuantity] = useState("");
  const [Total, setTotal] = useState(0);
  const [amount, setAmount] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [listCustomer, setlistCustomers] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredInvoicess, setFilteredInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loadingItems, setLoadingItems] = useState(true);
  const [loadingInvoices, setLoadingInvoices] = useState(true);
  const [loadingCustomers, setLoadingCustomers] = useState(true);
  const [loadingCreateInvoice, setLoadingCreateInvoice] = useState(false);
  const [selectedBillingTo, setSelectedBillingTo] = useState("");
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchCustomerQuery, setSearchCustomerQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [descriptions, setDescriptions] = useState([]);
  const [itemdatas, setitemdata] = useState([]);
  const [showNewCustomerForm, setShowNewCustomerForm] = useState(false);
  const [showNewItemForm, setShowNewItemForm] = useState(false);
  const [itemss, setItemss] = useState([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [classes, setClasses] = useState([]);
  const token = localStorage.getItem('token');

  const printableInvoiceRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    Item: '',
    Description: '',
    Price: '',
  });
  const [invoiceData, setInvoiceData] = useState({
    inID: '',
    customerName: '',
    Total: '',
    orderItems: [],
  });
  const handleSelect = (option) => {
    if (option && option.value) {
      const selectedOption = option.value;
      setSelectedOption(selectedOption);
      const selectedItem = data.find((item) => item.Item === selectedOption);
      if (selectedItem) {
        setItem(selectedItem.Item);
        setDescription(selectedItem.Description);
        setClasss(selectedItem.Classs);
        setRate(selectedItem.Price);
      } else {
        setItem("");
        setDescription("");
        setClasss("");
        setRate("");
        setDetailDescr("");
      }
    } else {
      setItem("");
      setDescription("");
      setClasss("");
      setRate("");
      setDetailDescr("");
    }
  };
  const handleSelectDescription = (option) => {
    if (option && option.value) {
      const selectedDescription = option.value;
      setDescription(selectedDescription);

      const selectedItem = data.find((item) => item.Description === selectedDescription);
      if (selectedItem) {

        setSelectedOption(selectedItem.Item);
        setItem(selectedItem.Item);
        setClasss(selectedItem.Classs);
        setRate(selectedItem.Price);
      } else {
        setItem("");
        setClasss("");
        setRate("");

        setDetailDescr("");
      }
    } else {
      setItem("");
      setClasss("");
      setDetailDescr("");

      setDescription("");
    }
  };
  const handleSelectCustomer = (e) => {
    if (e && e.label) {
      setCustomerName(e.label);
      setSelectedBillingTo(e.label);
    } else {
      setCustomerName("");
      setSelectedBillingTo("");
    }
  };
  useEffect(() => {
    const cachedData = localStorage.getItem("cachedData");
    if (cachedData !== null && cachedData !== "undefined") {
      setData(JSON.parse(cachedData));
    } else {
      fetchItems();
    }
    if (!showCreateForm) {

      fetchInvoices();
      fetchCustomers();
      fetchData();
    }
    const filtered = invoices?.filter((invoice) => {
      return invoice.customerName.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredInvoices(filtered);
  }, [showCreateForm, invoices, searchQuery]);
  useEffect(() => {
    const invoiceDataForPrinting = {
      inID,
      customerName,
      Total,
      DateTime,
      orderItems,
    };
    setInvoiceData(invoiceDataForPrinting);
  }, [inID, customerName, Total, DateTime, orderItems]);
  useEffect(() => {
  }, [invoiceData]);

  useEffect(() => {
    calculateTotal();
    const fetchOrderItems = async () => {
      try {
        const response = await axios.get('http://82.180.163.36:5000/api/orderItems',
          {
            headers: {
              'authorization': `Bearer ${token}`
            }
          });
        setOrderItems(response.data);
      } catch (error) {
        console.error('Error fetching order items:', error);
      }
    };
    fetchOrderItems();
  }, [orderItems])
  const fetchItems = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(apiUrlItems,
        {
          headers: {
            'authorization': `Bearer ${token}`
          }
        });
      setData(response.data);
      localStorage.setItem("cachedData", JSON.stringify(response.data));
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching items:", error);
      setLoadingItems(false);
      setIsLoading(false);
      toast.error("Error fetching items:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);

  };
  const filteredInvoices = invoices?.filter((invoice) => {
    return invoice.customerName.toLowerCase().includes(searchQuery.toLowerCase());
  });
  const offset = currentPage * itemsPerPage;
  const paginatedInvoices = filteredInvoices.slice(offset, offset + itemsPerPage);

  const fetchInvoices = async () => {
    try {
      const response = await axios.get(apiUrl,
        {
          headers: {
            'authorization': `Bearer ${token}`
          }
        });
      setInvoices(response.data);
      setLoadingInvoices(false);
    } catch (error) {
      console.error("Error fetching invoices:", error);
      setLoadingInvoices(false);
    }
  };

  const fetchCustomers = () => {
    setIsLoading(true);
    axios
      .get("http://82.180.163.36:5000/api/customers",
        {
          headers: {
            'authorization': `Bearer ${token}`
          }
        })
      .then((response) => {
        const customers = response.data?.map((customer) => ({
          value: customer.name,
          label: customer.name,
        }));
        setlistCustomers(customers);
      })
      .catch((error) => {
        console.error("Error fetching customers:", error);
      }).finally(() => {
        setIsLoading(false);
      });
  };
  const handleCreateOrderItem = async (e) => {
    e.preventDefault();
    if (!customerName || !selectedOption || !Classs) {
      toast.error("Please select customer ,Class and items before adding.", {
        position: "top-center",
      });
      return;
    }
    try {
      setLoadingCreateInvoice(true);

      const item = {
        amount,
        Item,
        Description,
        DetailsDescription,
        Classs,
        Rate,
        Quantity,
      };
      setOrderItems([...orderItems, item]);
      const invoiceDataForPrinting = {
        inID,
        customerName,
        Total,
        DateTime,
        orderItems,
      };
      setInvoiceData(invoiceDataForPrinting);
      setItem("");
      setDescription("");
      setClasss("");
      setRate("");
      setQuantity("");
      setAmount("");
      setSelectedOption("");
      setDetailDescr("");
      setLoadingCreateInvoice(false);
    } catch (error) {
      console.error("Error creating invoice:", error);
      toast.error("Failed to create invoice.");
    }
  };

  const handleCreateInvoice = async (e) => {
    e.preventDefault();

    if (!customerName || orderItems.length === 0 || DateTime == null) {
      toast.error("Please select customer,date and items before creating an invoice.", {
        position: "top-center",
      });
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(apiUrl, {
        inID,
        customerName,
        Total: parseFloat(Total),
        date: DateTime,
      });
      let InvoiceId = -1;
      console.log("response data : " + JSON.stringify(response.data));
      if (response.data) {
        InvoiceId = response.data._id;
      }

      const response1 = await axios.post(apiUrlOrderItems, {
        InvoiceId,
        orderItems: orderItems,
      });

      setOrderItems([]);
      setItem("");
      setDescription("");
      setClasss("");
      setRate("");
      setQuantity("");
      setAmount("");
      setDetailDescr("");
      setSelectedOption(""); // Reset the selectedOption state to "Not selected"
      setSelectedBillingTo(""); // Reset the selectedBillingTo state to "Not selected"
      setShowCreateForm(false);
    } catch (error) {
      console.error("Error creating invoice:", error);
      toast.error("Failed to create invoice.");
      setIsLoading(false);


    } finally {
      setIsLoading(false); // Enable the button again after the process is complete
    }
  };

  const handleSaveitem = (formData) => {
    try {
      axios.post('http://82.180.163.36:5000/api/items', formData,
        {
          headers: {
            'authorization': `Bearer ${token}`
          }
        });
      setFormData({
        Item: '',
        Description: '',
        Price: '',
      });
      toast.success('Item saved successfully.');
    } catch (error) {
      toast.error('Error saving item:', error);
      console.error('Error saving item:', error);
    } finally {
      setLoading(false); // Hide the loading indicator whether the save was successful or not
    }
    fetchItems();
  }
  const handleSaveCustomer = (customerName) => {
    axios
      .post('http://82.180.163.36:5000/api/customers', {
        customerName,
      },
        {
          headers: {
            'authorization': `Bearer ${token}`
          }
        })
      .then((response) => {
        console.log('New customer saved:', response.data);
        //  setName('');
        fetchCustomers();
      })
      .catch((error) => {
        console.error('Error saving customer data:', error);
      });
  };
  const handleDeleteInvoice = async (id) => {
    try {
      await axios.delete(`${apiUrl}/${id}`,
        {
          headers: {
            'authorization': `Bearer ${token}`
          }
        });
      setInvoices(invoices?.filter((invoice) => invoice._id !== id));
      toast.success("Invoice deleted successfully!");
    } catch (error) {
      console.error("Error deleting invoice:", error);
      toast.error("Failed to delete invoice.");
    }
  };

  const calculateTotal = () => {
    const totalAmount = orderItems.reduce((sum, item) => {
      return sum + item.amount;
    }, 0);
    setTotal(totalAmount);
  }
  const handleRateChange = (e) => {
    const inputChar = String.fromCharCode(e.which);
    const validInput = /^[0-9.]*$/.test(inputChar);

    if (!validInput) {
      setShowError(true); // Set the flag to show the error message
      e.preventDefault();
      setTimeout(() => {
        setShowError(false); // Hide the error message after 2 seconds (adjust as needed)
      }, 2000);
    } else {
      setShowError(false); // Hide the error message when the input is valid
      setRate(e.target.value);
    }
  };
  const handleInputChange = (searchValue) => {
    setSearchCustomerQuery(searchValue);
    if (searchValue === "") {
      setSelectedBillingTo("");

      setCustomerName("");// Set the selectedBillingTo state to an empty string if the search input is empty
    }
    fetchCustomers(searchValue);
  };
  const handleAddItem = (newItem) => {
    setItems((prevItems) => [...prevItems, newItem]);
  };

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };
  const handleDelete = async (index) => {
    try {
      const updatedOrderItems = [...orderItems];
      const deletedAmount = updatedOrderItems[index].amount;
      // Remove the item at the specified index from the copy
      updatedOrderItems.splice(index, 1);
      // Update the state with the new array without the deleted item

      setOrderItems(updatedOrderItems);
      setTotal((prevTotal) => prevTotal - deletedAmount);
    } catch (error) {
      console.error('Error deleting item:', error);
      toast.error('Failed to delete item.');
    }
  };
  const fetchData = async () => {
    const response = await axios.get('http://82.180.163.36:5000/api/classes',
      {
        headers: {
          'authorization': `Bearer ${token}`
        }
      });
    setClasses(response.data);
  };
  const handlePrint = () => {

    const invoiceDataForPrinting = {
      inID,
      customerName,
      Total,
      orderItems,
    };
    console.log('inovice list: ' + JSON.stringify(invoiceDataForPrinting));
    setInvoiceData(invoiceDataForPrinting);
    window.print();
  };
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      minWidth: "200px", // Set the width of the control (search input and dropdown)
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "14px", // Adjust the font size
    }),
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const day = selectedDate.getDate() +1;
    const month = selectedDate.getMonth() + 1; // Months are zero-based, so we add 1
    const year = selectedDate.getFullYear();

    const formatted = `${month} /${day} /${year}`;
    setDateTime(formatted);
  };
  return (
    <div className="invoice-list-container">

      {!showCreateForm &&

        <div class="form-row">
          <label>Search Customer:</label>
          <input
            type="text" style={{ width: '20%' }}
            value={searchQuery}
            onChange={handleSearchChange}

            placeholder="Enter customer name..."
          />
        </div>}
      {showCreateForm ? (

        <div className="main-createinvoice">
          <div class="form-row">
            <CustomerForm handleSaveCustomer={handleSaveCustomer} />
            <AddItemForm handleSaveitem={handleSaveitem} />
          </div>
          <h3></h3>
          <form onSubmit={handleCreateOrderItem}>

            <table>
              <tr>
                <td>
                  <div class="form-container">

                    <div class="form-row">
                      <label>Billing To:</label>
                      <Select
                        class="custom-select-container"
                        value={
                          customerName
                            ? { label: customerName, value: customerName }
                            : null
                        }
                        isLoading={isLoading}
                        onChange={handleSelectCustomer}
                        options={listCustomer}
                        isClearable
                        isSearchable
                        style={selectStyles}
                        placeholder="Type to search for Billing To..."
                      >
                      </Select>

                    </div>
                    <div class="form-row">
                      <label>Invoice No:</label>
                      <input
                        type="text"
                        onChange={(e) => setinID(e.target.value)}
                        required
                      />
                    </div>
                    <div class="form-row">
                      <label>Date and Time:</label>
                      <input
                        type="Date"
                        // Use datetime-local input type for date and time
                        onChange={handleDateChange}
                        required
                      />
                    </div>
                    <div class="custom-line"></div>
                    <div className="form-row">
                      <label>Item:</label>
                      <Select

                        class="custom-select"
                        value={
                          selectedOption
                            ? { label: selectedOption, value: selectedOption }
                            : null
                        }

                        isLoading={isLoading}
                        onChange={handleSelect}
                        options={data.map((item) => ({ value: item.Item, label: item.Item }))}
                        isClearable
                        isSearchable
                        style={selectStyles}
                        placeholder="Select an item...                    "
                      >    </Select>
                    </div>

                    <div className="form-row">
                      <label>Description:</label>
                      <Select

                        value={
                          Description
                            ? { label: Description, value: Description }
                            : null
                        }

                        isLoading={isLoading}
                        onChange={handleSelectDescription}
                        options={data.map((item) => ({ value: item.Description, label: item.Description }))}
                        isClearable
                        isSearchable
                        style={selectStyles}
                        placeholder="Select an Description..."
                      /></div>
                    <div className="form-row">

                      <label> Details:</label>
                      <textarea rows={5} cols={27}
                        type="text"
                        onChange={(e) => setDetailDescr(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-row">
                      <label>Class:</label>
                      <select
                        className="custom-select"
                        value={Classs}

                        style={selectStyles}
                        onChange={(e) => setClasss(e.target.value)}
                        required
                      >
                        <option value="">Not selected</option>
                        {classes.map((option) => (
                          <option key={option.name} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <div class="form-row">
                        <label>Rate:</label>
                        <input
                          type="text"
                          value={Rate.toString()}
                          onKeyPress={handleRateChange}
                          onChange={(e) => setRate(e.target.value)}
                          required
                        />
                        {showError && (
                          <div className="popup">
                            Please enter a valid number.
                          </div>
                        )}
                      </div>
                    </div>
                    <div class="form-row">
                      <label>Quantity:</label>
                      <input
                        type="number"
                        value={Quantity}
                        onChange={(e) => {
                          setQuantity(e.target.value);
                          setAmount(Rate * parseFloat(e.target.value));
                        }}
                        required
                      />
                    </div>
                    <div class="form-row">
                      <label>Amount:</label>
                      <input disabled
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <table><tr> <td></td><td></td><td> <button style={{ width: '80%' }} type="button" onClick={() => setShowCreateForm(false)}>
              Cancel
            </button>
            </td>
              <td> <button style={{ width: '80%' }} type="submit">   <FontAwesomeIcon icon={faPlus} />Add Item</button> </td></tr></table>

          </form>
        </div>
      ) : (
        <div style={{ width: '50%' }}>
          <button style={{ width: '50%' }} onClick={() => { setSearchQuery(""); setShowCreateForm(true) }}>    <FontAwesomeIcon icon={faPlus} />Create New Invoice</button>

        </div>
      )}
      {showCreateForm && orderItems.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>Item</th>
              <th>Description</th>
              <th>class</th>
              <th>Quantity</th>
              <th>Rate</th>
              <th>Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {orderItems.map((orderItem, index) => (
              <OrderItem key={index} orderItem={orderItem} onDelete={() => handleDelete(index)} className={index % 2 === 0 ? "highlighted-row" : ""} />
            ))}
          </tbody>
          <tr><td style={{ paddingLeft: '63%', color: 'black' }}> <label>Total:  </label></td><td colSpan="8" style={{ paddingLeft: '75%', color: 'black' }} className="highlighted-total">{Total.toLocaleString()}</td></tr>

        </table>

      ) : (
        <p style={{ display: 'none' }}>No invoices found.</p>
      )}
      {!showCreateForm && filteredInvoices.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Invoice ID</th>
              <th>Customer Name</th>
              <th>Total</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredInvoices.map((invoice) => (
              <Invoice
                key={invoice._id}
                invoice={invoice}
                onDelete={() => handleDeleteInvoice(invoice._id)}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <p></p>
      )}
      {showCreateForm && orderItems.length > 0 &&
        <>
          <div className="form-row">
            <button
              style={{ width: '25%', backgroundColorr: "green", marginLeft: '41%' }}
              onClick={handleCreateInvoice}
              disabled={isLoading} // Disable the button if isLoading is true
            >
              <FontAwesomeIcon icon={faPlus} />Save to Database </button>


            <div>
              <ReactToPrint
                trigger={() => (
                  <button style={{ width: '90%', backgroundColor: 'black', color: 'white' }}>
                    <FontAwesomeIcon icon={faPrint} /> Print
                  </button>
                )}
                content={() => printableInvoiceRef.current}
              />
              <div style={{ display: "none" }}>
                <PrintableComponent
                  invoiceData={invoiceData}
                  ref={printableInvoiceRef}
                />
              </div>
            </div>
          </div>


        </>
      }
      {!showCreateForm && filteredInvoices.length > 20 && <ReactPaginate
        previousLabel={
          <span>
            <FontAwesomeIcon icon={faChevronLeft} /> Prev
          </span>
        }
        nextLabel={
          <span>
            Next <FontAwesomeIcon icon={faChevronRight} />
          </span>
        }

        breakLabel={"..."}
        pageCount={Math.ceil(filteredInvoices.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />}

      {loadingInvoices && (
        <div className="loading-message">
          <div className="loader" />
          Loading invoices...
        </div>
      )}

      {loadingCreateInvoice && (
        <div className="loading-message">
          <div className="loader" />
          Creating invoice...
        </div>
      )}
    </div>
  );
};


const unitOptions = [
  { label: "numbers  أعداد", value: " numbers أعداد" },
  { label: "pieces  قِطَع", value: "pieces قِطَع " },
  { label: "cartons  كرتون", value: "cartons كرتون" },
  { label: "mililitrs   ملليلتر", value: "mililitrs ملليلتر" },
  { label: "litres   لترات", value: "litres لترات" },
  { label: "grams   جرامات", value: "grams جرامات" },
  { label: "kilograms   كيلوغرامات", value: " kilograms كيلوغرامات" },
  { label: "Milimetres   ملليمتر", value: "Milimetres ملليمتر" },
  { label: "centimetres   سم", value: "centimetres سم" },
  { label: "metres   أمتار", value: "metres أمتار" },
  { label: "Inches   بوصة", value: "Inches   بوصة" },
  { label: "Feet   قدم", value: "Feet    قدم" },
];
export default InvoiceList;
