import React from "react";
import { BeatLoader } from "react-spinners";

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <BeatLoader color="#3498db" loading={true} size={40} />
    </div>
  );
};

export default LoadingSpinner;
