import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ItemList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import { faChevronLeft, faChevronRight, faEdit, faCheck, faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { BeatLoader } from 'react-spinners';

const ITEMS_PER_PAGE = 5;
function ItemList() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 5;
  const [userRole, setUserRole] = useState('');
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  // Additional state for inline editing
  const [editingItemId, setEditingItemId] = useState(null);
  const [editedItem, setEditedItem] = useState({ Item: '', Description: '', Price: '' });

  useEffect(() => {
    const logedInUser = JSON.parse(localStorage.getItem('logedInUser'));
    if (logedInUser && logedInUser.login === true) {
      setUserRole(logedInUser.Role);
    }
  }, []);

  useEffect(() => {
    const cachedData = localStorage.getItem("cachedData");
    if (cachedData!== null  && cachedData !== "undefined") {
      setItems(JSON.parse(cachedData));
      setLoading(false); // Set loading to false after setting the data from the cache
    } else {
      fetchData();
    }
  }, [pageNumber, searchQuery]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await axios.get(`http://82.180.163.36:5000/api/items/search`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
     // const response = await axios.get(`http://82.180.163.36:5000/api/items?page=${pageNumber + 1}&perPage=${itemsPerPage}&search=${searchQuery}`);
      setItems(response.data);
      setTotalItemsCount(response.data.totalItemsCount); // Set total items count
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://82.180.163.36:5000/api/items/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      fetchData();
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleEdit = (item) => {
    // Start editing the selected item
    setEditingItemId(item._id);
    setEditedItem({ Item: item.Item, Description: item.Description, Price: item.Price });
  };

  const handleSave = async (id) => {
    try {
      // Update the item using the edited values
      await axios.put(`http://82.180.163.36:5000/api/items/${id}`, editedItem, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setEditingItemId(null); // Stop editing
      fetchData();
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleCancelEdit = () => {
    // Cancel editing
    setEditingItemId(null);
    setEditedItem({ Item: '', Description: '', Price: '' });
  };

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
    fetchData();
  };

  const handleSearch = () => {
    fetchData();
  };
  const filtereditem= items.filter((item) =>
  item?.Item?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  // Calculate offset and get the items for the current page
  const pageCount = Math.ceil(filtereditem.length/ITEMS_PER_PAGE);
  const offset = pageNumber * ITEMS_PER_PAGE;
  const currentPageItems = filtereditem.slice(offset, offset + ITEMS_PER_PAGE);

  return (
    <div className="item-list-container">
      <h2 className="item-list-heading">All Items</h2>
      <div className="search-container">
        <input
          type="text" style={{width:'30%'}}
          placeholder="Search for Item Name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      {loading ? (
         <div className="loader-overlay">
         <BeatLoader color="#3498db" loading={loading} size={40} />
       </div>
      ) : (
        <>
          <table className="item-list-table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Description</th>
                <th>Price</th>
                {userRole === 'Admin' && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {currentPageItems.map((item) => (
                <tr key={item._id}>
                  <td>
                    {editingItemId === item._id ? (
                      <input
                        type="text"
                        value={editedItem.Item}
                        onChange={(e) => setEditedItem({ ...editedItem, Item: e.target.value })}
                      />
                    ) : (
                      item.Item
                    )}
                  </td>
                  <td>
                    {editingItemId === item._id ? (
                      <input
                        type="text"
                        value={editedItem.Description}
                        onChange={(e) => setEditedItem({ ...editedItem, Description: e.target.value })}
                      />
                    ) : (
                      item.Description
                    )}
                  </td>
                  <td>
                    {editingItemId === item._id ? (
                      <input
                        type="text"
                        value={editedItem.Price}
                        onChange={(e) => setEditedItem({ ...editedItem, Price: e.target.value })}
                      />
                    ) : (
                      item.Price
                    )}
                  </td>
                  <td>
                    {userRole === 'Admin' && (
                      <div>
                        {editingItemId === item._id ? (
                          <div>
                            <button
                              className="edit-button"
                              type="button"
                              onClick={() => handleSave(item._id)}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                              Save
                            </button>
                            <button
                              className="cancel-button"
                              type="button"
                              onClick={() => handleCancelEdit()}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <button
                            className="edit-button"
                            type="button"
                            onClick={() => handleEdit(item)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                            Edit
                          </button>
                        )}
                        <button
                          className="delete-button"
                          type="button"
                          onClick={() => handleDelete(item._id)}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={<span><FontAwesomeIcon icon={faChevronLeft} /> Prev</span>}
            nextLabel={<span>Next <FontAwesomeIcon icon={faChevronRight} /></span>}
            breakLabel={'...'}
            pageCount={pageCount} // Use totalItemsCount for page count
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            forcePage={pageNumber}
            previousClassName={pageNumber === 0 ? 'disabled' : ''}
            nextClassName={pageNumber === pageCount - 1 ? 'disabled' : ''}
            previousLinkClassName={pageNumber === 0 ? 'disabled-link' : ''}
            nextLinkClassName={pageNumber === pageCount - 1 ? 'disabled-link' : ''}
          />
        </>
      )}
    </div>
  );
}

export default ItemList;
