// src/components/ClassForm.js
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './styles.css';

const ClassForm = ({ existingClasses, onClassSaved }) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const token=localStorage.getItem('token');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if a class with the same name already exists
    if (existingClasses?.some((cls) => cls.name === name)) {
      setError('A class with the same name already exists.');
      return;
    }

    // Send a POST request to create a new class
    axios
      .post('http://82.180.163.36:5000/api/classes', { name }, 
      {
        headers: {
          'authorization': `Bearer ${token}`
        }})
      .then(() => {
       
      toast.success("Class Added Successfuly:", name);
        onClassSaved(); // Call the parent component's callback to refresh the class list
        setName(''); // Clear the text box
        setError('');
        setSuccessMessage('');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <h1>Add New Class</h1>
      {error && <div className="error">{error}</div>}
      {successMessage && <div className="success">{successMessage}</div>}
      <form onSubmit={handleSubmit}>
        <div className='form-row'>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => {
              setError('');
              setSuccessMessage('');
              setName(e.target.value);
            }}
          />
          
        <button type="submit">Create</button>
        </div>
      </form>
    </div>
  );
};

export default ClassForm;
