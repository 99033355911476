import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ForwardedInvoicePrint from "./InvoicePrint"; // Import the InvoicePrint component
import ReactToPrint from "react-to-print"; // Import the ReactToPrint component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus, faCog, faUsers, faFileInvoiceDollar, faEdit, faTimes,
  faTrashAlt,
  faSignOutAlt,
  faPrint
} from '@fortawesome/free-solid-svg-icons';

const apiUrl = "http://82.180.163.36:5000/api/invoices";

const Invoice = ({ invoice, onDelete }) => {
  const invoiceRef = React.createRef();
  const [isEditing, setIsEditing] = useState(false);
  
  const [userRole, setUserRole] = useState('');
  const [editedCustomerName, setEditedCustomerName] = useState(
    invoice.customerName
  );
  const [editedAmount, setEditedAmount] = useState(invoice.amount);
  const [editedItem, setEditedItem] = useState(invoice.Item);
  const [editedDescription, setEditedDescription] = useState(
    invoice.Description
  );
  const [editedClasss, setEditedClasss] = useState(invoice.Classs);
  const [editedRate, setEditedRate] = useState(invoice.Rate);
  const [editedQuantity, setEditedQuantity] = useState(invoice.Quantity);
  const [editedTotal, setEditedTotal] = useState(invoice.Total);
  
  useEffect(() => {
    const logedInUser = JSON.parse(localStorage.getItem('logedInUser'));
    if (logedInUser && logedInUser.login === true) {
      setUserRole(logedInUser.Role);
    }
  }, []);

  const handleUpdate = async () => {
    try {
      const response = await axios.put(`${apiUrl}/${invoice._id}`, {
        customerName: editedCustomerName,
        amount: editedAmount,
        Item: editedItem,
        Description: editedDescription,
        Classs: editedClasss,
        Rate: editedRate,
        Quantity: editedQuantity,
        Total: editedTotal,
      },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
      toast.success("Invoice updated successfully!");
      setIsEditing(false);
      setEditedCustomerName(response.data.customerName);
      setEditedAmount(response.data.amount);
      setEditedItem(response.data.Item);
      setEditedDescription(response.data.Description);
      setEditedClasss(response.data.Classs);
      setEditedRate(response.data.Rate);
      setEditedQuantity(response.data.Quantity);
      setEditedTotal(response.data.Total);
    } catch (error) {
      console.error("Error updating invoice:", error);
      toast.error("Failed to update invoice.");
    }
  };

  return (
    <tr>
      <td>{invoice.inID}</td>
      <td>
        {isEditing ? (
          <input
            type="text"
            value={editedCustomerName}
            onChange={(e) => setEditedCustomerName(e.target.value)}
          />
        ) : (
          invoice.customerName
        )}
      </td>
      <td>
        {isEditing ? (
          <input
            type="number"
            value={editedTotal}
            onChange={(e) => setEditedTotal(e.target.value)}
          />
        ) : (
          `${invoice.Total.toLocaleString()}`
        )}
      </td>
      <td>
        {isEditing ? (
          <>
            <button style={{ width: '50%', backgroundColor: 'green', color: 'white' }} onClick={handleUpdate}>  <FontAwesomeIcon icon={faFileInvoiceDollar} />Save</button>
            <button style={{ width: '50%', backgroundColor: 'Red', color: 'white' }} onClick={() => setIsEditing(false)}>  <FontAwesomeIcon icon={faTimes} /> Cancel</button>
          </>
        ) : (
          <>
           {userRole === 'Admin' && (
            <button style={{width:'50%',backgroundColor: 'Red', color: 'white' }}   onClick={onDelete}> <FontAwesomeIcon icon={faTrashAlt}/> Delete</button> )}
          </>
        )}
        {/* Print button using ReactToPrint */}
        <ReactToPrint
          trigger={() => <button style={{ width: '33%', backgroundColor: 'black', color: 'white' }} >    <FontAwesomeIcon icon={faPrint} /> Print</button>}
          content={() => invoiceRef.current} // Pass the ref to ForwardedInvoicePrint
        />
        {/* Print preview section */}
        <div style={{ display: "none" }}>
          {/* Pass the content to the ForwardedInvoicePrint component */}
          <ForwardedInvoicePrint ref={invoiceRef} props={invoice} />{" "}
          {/* Render the ForwardedInvoicePrint component */}
        </div>
      </td>
    </tr>
  );
};

export default Invoice;
