import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css'; import { useHistory } from 'react-router-dom';
import axios from 'axios'; // Import axios library for making API requests
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';


import UserLogin from '../src/components/UserLogin';
import UserList from '../src/components/UserList';
import UserRegistration from '../src/components/UserRegistration';
import MainScreen from '../src/components/MainScreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faCog,
  faUsers,
  faFileInvoiceDollar,
  faEdit,
  faTimes,
  faPrint,
  faTrashAlt,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [registered, setRegistered] = useState(true);
  const [userRole, setUserRole] = useState('');

  // Check for user login state in local storage on initial load
  useEffect(() => {
    const logedInUser = JSON.parse(localStorage.getItem('logedInUser'));
    if (logedInUser && logedInUser.login === true) {
      setLoggedIn(true);
      setUsername(logedInUser.Name);
      setUserRole(logedInUser.Role);
    }
  }, []);
  const setupLogoutTimeout = async () => {

    const logedInUser = JSON.parse(localStorage.getItem('logedInUser'));

    if (logedInUser && logedInUser.login === true) {
      const email = logedInUser.Email;
      const password = logedInUser.Password;
      const response = await axios.post("http://82.180.163.36:5000/api/users/login", {
        email,
        password,
      });
      const data = response.data;
      if (data) {

      } else {
        localStorage.clear();
        window.location.href = '/';
      }
    } else {

      const logedInUser1 = JSON.parse(localStorage.getItem('logedInUser'));
      if (logedInUser1 != null) {
        const email = logedInUser1.Email;
        const password = logedInUser1.Password;
        const response = await axios.post("http://82.180.163.36:5000/api/users/login", {
          email,
          password,
        });
        const data = response.data;
        if (!data) {
          localStorage.clear();
          window.location.href = '/';
        }
      }
      else {

        window.location.href = '/';
      }
    }
  };

  setInterval(setupLogoutTimeout, 90000);
  const handleLogin = (user) => {
    setLoggedIn(true);
    setUsername(user.Name);
    setUserRole(user.Role);

    // Save the login state to local storage
    localStorage.setItem('logedInUser', JSON.stringify({ ...user, login: true }));
    try {
      const response = axios.get('http://82.180.163.36:5000/api/items/search');
      localStorage.setItem('cachedData', JSON.stringify(response.data));
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };



  const handleLogout = () => {
    setLoggedIn(false);
    setUsername('');
    setUserRole('');

    // Clear the login state from local storage
    localStorage.removeItem('logedInUser');
  };

  const handleRegistration = (message) => {
    setRegistered(true);
    console.log('Registration successful:', message);
  };

  return (
    <Router>
      <div className="App">
        <ToastContainer />
        {loggedIn && (
          <>
            {/* <button onClick={handleLogout} className="logout-button">
            Logout         <label>{username}</label>
          </button> */}

          </>
        )}
        <div>
          <Routes>
            <Route path="/" exact element={loggedIn ? <MainScreen userRole={userRole} /> : <UserLogin onLogin={handleLogin} />} />

            <Route path="/ " exact element={registered ? (<Navigate to="/login" />) : (<UserRegistration onRegistration={handleRegistration} />)} />
            <Route path="/" exact element={loggedIn ? <MainScreen /> : <Navigate to="/login" />} />
            <Route path="/" exact element={loggedIn ? <Navigate to="/" /> : <UserLogin onLogin={handleLogin} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;