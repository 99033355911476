// src/components/ClassManagement.js
import React, { useState } from 'react';
import './ClassManagement.css'; // Import component-specific styles
import ClassForm from './ClassForm';
import ClassDetail from './ClassDetail';
import ClassList from './ClassList';

const ClassManagement = () => {
  const [activeForm, setActiveForm] = useState('list'); // Default to 'list'

  const handleFormChange = (formName) => {
    setActiveForm(formName);
  };

  return (
    <div className="class-management-container">
          <br></br>
      <h1 className="main-heading">   <br></br> Class Management</h1>
      <br></br>
      <div className="form-tabs">
        <button
          className={activeForm === 'list' ? 'active' : ''}
          onClick={() => handleFormChange('list')}
        >
          Show Class List
        </button>

        <button
          className={activeForm === 'form' ? 'active' : ''}
          onClick={() => handleFormChange('form')}
        >
          Add New Class
        </button>
      </div>
      <br></br>

      <div className="form-content">
        {activeForm === 'list' && <ClassList />}
        {activeForm === 'form' && <ClassForm />}
        {/* {activeForm === 'detail' && <ClassDetail />} */}
      </div>
    </div>
  );
};

export default ClassManagement;
