import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ClassList = () => {
  const [classes, setClasses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [editableClassId, setEditableClassId] = useState(null);
  const token=localStorage.getItem('token');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await axios.get('http://82.180.163.36:5000/api/classes', 
    {
      headers: {
        'authorization': `Bearer ${token}`
      }});
    setClasses(response.data);
  };

  const handleCreate = async (name) => {
    await axios.post('http://82.180.163.36:5000/api/classes', { name }, 
    {
      headers: {
        'authorization': `Bearer ${token}`
      }});
    fetchData();
  };

  const handleUpdate = async (id, newName) => {
    await axios.put(`http://82.180.163.36:5000/api/classes/${id}`, { name: newName }, 
    {
      headers: {
        'authorization': `Bearer ${token}`
      }});
    
    toast.success("Class updated Successfuly:", newName);
    fetchData();
  };

  const handleDelete = async (id) => {
    await axios.delete(`http://82.180.163.36:5000/api/classes/${id}`, 
    {
      headers: {
        'authorization': `Bearer ${token}`
      }});
    
    toast.error("deleted Successfuly:", id);
    fetchData();
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };
  const filteredClasses = classes.filter((cls) =>
    cls.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div>
      <h1>Classes</h1>
      <input style={{width:'50%'}}
        type="text"
        placeholder="Search classes"
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
      /> 
      <table>
        <thead>
          <tr>
            <th style={{width:'70%'}}>Name</th>
            <th style={{width:'30%'}}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredClasses.map((cls) => (
            <tr key={cls._id}>
              <td>
                {editableClassId === cls._id ? (
                  <input
                    type="text"
                    value={cls.name}
                    onChange={(e) =>
                      setClasses((prevClasses) =>
                        prevClasses.map((c) =>
                          c._id === cls._id ? { ...c, name: e.target.value } : c
                        )
                      )
                    }
                  />
                ) : (
                  cls.name
                )}
              </td>
              <td>
                {editableClassId === cls._id ? (
                  <>
                    <button
                      onClick={() => {
                        handleUpdate(cls._id, cls.name);
                        setEditableClassId(null);
                      }}
                    >
                      Save
                    </button>
                    <button onClick={() => setEditableClassId(null)}>Cancel</button>
                  </>
                ) : (
                  <button onClick={() => setEditableClassId(cls._id)}>Edit</button>
                )}
                <button onClick={() => handleDelete(cls._id)} style={{backgroundColor:'red'}}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ClassList;
