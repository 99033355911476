import React, { useState, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import ForwardedInvoicePrint from './InvoicePrint'; // Import the InvoicePrint component
import ReactToPrint from 'react-to-print'; // Import the ReactToPrint component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './orderItem.css';
const apiUrl = 'http://82.180.163.36:5000/api/invoices';

const OrderItem = ({ orderItem, onDelete }) => {

  const invoiceRef = React.createRef();
  const [isEditing, setIsEditing] = useState(false);
  const [editedAmount, setEditedAmount] = useState(orderItem.amount);
  const [editedItem, setEditedItem] = useState(orderItem.Item);
  const [editedDescription, setEditedDescription] = useState(orderItem.Description);
  const [editedClasss, setEditedClasss] = useState(orderItem.Classs);
  const [editedRate, setEditedRate] = useState(orderItem.Rate);
  const [editedQuantity, setEditedQuantity] = useState(orderItem.Quantity);

  const handleDelete = async () => {
    try {
      // Call the onDelete function passed as a prop with the index
      onDelete();
      //toast.success('Item deleted successfully!');
    } catch (error) {
      console.error('Error deleting item:', error);
      toast.error('Failed to delete item.');
    }
  };
  const handleUpdate = async () => {
    try {
      const response = await axios.put(`${apiUrl}/${orderItem._id}`, {
        amount: editedAmount,
        Item: editedItem,
        Description: editedDescription,
        Classs: editedClasss,
        Rate: editedRate,
        Quantity: editedQuantity,
      }, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      toast.success('Invoice updated successfully!');
      setIsEditing(false);
      setEditedAmount(response.data.amount);
      setEditedItem(response.data.Item);
      setEditedDescription(response.data.Description);
      setEditedClasss(response.data.Classs);
      setEditedRate(response.data.Rate);
      setEditedQuantity(response.data.Quantity);
    } catch (error) {
      console.error('Error updating invoice:', error);
      toast.error('Failed to update invoice.');
    }
  };


  return (

    <tr>
      <td>{orderItem._id}</td>
      <td>
        {isEditing ? (
          <input
            type="text"
            value={editedItem}
            onChange={(e) => setEditedItem(e.target.value)}
          />
        ) : (
          orderItem.Item
        )}
      </td>
      <td>
        {isEditing ? (
          <input
            type="text"
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
          />
        ) : (
          orderItem.Description
        )}
      </td>
      <td>
        {isEditing ? (
          <input
            type="text"
            value={editedClasss}
            onChange={(e) => setEditedClasss(e.target.value)}
          />
        ) : (
          orderItem.Classs
        )}
      </td>
      <td>
        {isEditing ? (
          <input
            type="number"
            value={editedQuantity}
            onChange={(e) => setEditedQuantity(e.target.value)}
          />
        ) : (
          orderItem.Quantity
        )}
      </td>
      <td>
        {isEditing ? (
          <input
            type="number"
            value={editedRate}
            onChange={(e) => setEditedRate(e.target.value)}
          />
        ) : (
          orderItem.Rate
        )}
      </td>
      <td>
        {isEditing ? (
          <input
            type="number"
            value={editedAmount}
            onChange={(e) => setEditedAmount(e.target.value)}
          />
        ) : (
          `${orderItem.amount.toLocaleString()}`
        )}
      </td>
      <td>
        {isEditing ? (
          <>
            <button onClick={handleUpdate}>Save</button>
            <button onClick={() => setIsEditing(false)}>Cancel</button>
          </>
        ) : (
          <>
            {/* <button onClick={() => setIsEditing(true)}>Update</button> */}
            <button onClick={handleDelete} className="remove-button" > <FontAwesomeIcon icon={faTrash} /> Remove</button>
          </>
        )}
        {/* Print button using ReactToPrint */}
        <ReactToPrint
          trigger={() => <button style={{ display: 'none' }}>Print</button>}
          content={() => invoiceRef.current} // Pass the ref to ForwardedInvoicePrint
        />
        {/* Print preview section */}
        <div style={{ display: 'none' }}>
          {/* Pass the content to the ForwardedInvoicePrint component */}
          <ForwardedInvoicePrint ref={invoiceRef} /> {/* Render the ForwardedInvoicePrint component */}
        </div>

      </td>
    </tr>
  );
};

export default OrderItem;
