import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UserRegistration from './UserRegistration'; // Import the UserRegistration component
import './Userlist.css'; // Import the CSS file for styling
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userRoles, setUserRole] = useState('');
  const [showPassword, setShowPassword] = useState(''); 
  const [userRole, setUserRoles] = useState('');
  const token=localStorage.getItem('token');
  useEffect(() => {
    const logedInUser = JSON.parse(localStorage.getItem('logedInUser'));
    if (logedInUser && logedInUser.login === true) {
      setUserRoles(logedInUser.Role);
    }
  }, []);
  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const response = await axios.get('http://82.180.163.36:5000/api/users', 
      {
        headers: {
          'authorization': `Bearer ${token}`
        }});
      const data = response.data;
      setUsers(data);
      
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error:', error)
    }finally{
    }
  };

  // Function to handle registration
  const handleRegistration = (message) => {
    console.log(message); // Display the registration message (success or failure)
    getUsers(); // Update the user list after registration
  };

  const handleEditUser = (userId) => {
    const user = users.find((user) => user._id === userId);
    setEditingUserId(userId);
    setUserName(user.Name); // Initialize the state values with the user's data
    setUserEmail(user.Email);
    setUserRole(user.Role);
    setShowPassword(user.Password);
  };

  const handleUpdateUser = async (userId, updatedUserData) => {
    try {
      await axios.put(`http://82.180.163.36:5000/api/users/${userId}`, updatedUserData
      , 
      {
        headers: {
          'authorization': `Bearer ${token}`
        }});
      setEditingUserId(null);
      getUsers();
      
      toast.success("udpate successfully",userId);
    } catch (error) {
      console.error('Error updating user:', error);
      toast.error('Error updating user:', error);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`http://82.180.163.36:5000/api/users/${userId}`
      , 
      {
        headers: {
          'authorization': `Bearer ${token}`
        }});
      getUsers();
      
      toast.success("deleted successfully",userId);
    } catch (error) {
      console.error('Error deleting user:', error);
      
      toast.error('Error deleting user:', error);
    }
  };
  return userRole === 'Admin' ?(
    
    <div className="customer-container">
      <div className="left-column">
      <div className="title-bar">
        <h2 className="user-list-heading">User List</h2></div>
      <table className="user-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Password</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id} className="user-row">
              <td>{editingUserId === user._id ? <input type="text"  onChange={(e) => setUserName(e.target.value)} defaultValue={user.Name} /> : user.Name}</td>
              <td>{editingUserId === user._id ? <input type="text" onChange={(e) => setUserEmail(e.target.value)}  defaultValue={user.Email} /> : user.Email}</td>
              <td >{editingUserId === user._id ? <input type="password"  onChange={(e) => setShowPassword(e.target.value)}  defaultValue={user.Password} /> : '********'}</td>
              <td className='from-row'>
                {editingUserId === user._id ? (
                  <select value={userRoles} onChange={(e) => setUserRole(e.target.value)}>
                    <option value="Admin">Admin</option>
                    <option value="User">User</option>
                  </select>
                ) : (
                  user.Role
                )}
              </td>
              <td>
                {editingUserId === user._id ? (
                  <button className="save-button" onClick={() => handleUpdateUser(user._id, { Name: userName,Password:showPassword, Email: userEmail, Role: userRoles })}>Save</button>
                ) : (
                  <>
                    <button className="edit-button" onClick={() => handleEditUser(user._id)}>Edit</button>
                    <button className="delete-button" onClick={() => handleDeleteUser(user._id)}>Delete</button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <div className='right-column'>
      <div className="title-bar">
        <h2 className="user-list-heading">Create New Registration Form </h2></div>
        <UserRegistration userRole={userRole} onRegistration={handleRegistration} />
        </div>
      </div>
  ): null;
};

export default UserList;
