import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUpload, faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { BeatLoader } from 'react-spinners';
import './Customer.css';

const ITEMS_PER_PAGE = 5;

const Customer = () => {
  const [customers, setCustomers] = useState([]);
  const [name, setName] = useState('');
  const [editingId, setEditingId] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [customerFile, setCustomerFile] = useState(null);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [file, setFile] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isItemFormOpen, setIsItemFormOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  useEffect(() => {
    const logedInUser = JSON.parse(localStorage.getItem('logedInUser'));
    if (logedInUser && logedInUser.login === true) {
      setUserRole(logedInUser.Role);
    }
  }, []);
  useEffect(() => {
    fetchCustomers();
  }, [pageNumber, searchQuery]);

  const fetchCustomers = () => {
    setIsLoading(true);
    axios
      .get('http://82.180.163.36:5000/api/customers', {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`, // Set the authorization header
        },
      })
      .then((response) => {
        setCustomers(response.data);
      })
      .catch((error) => {
        console.error('Error fetching customers:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleCustomerFileChange = (event) => {
    setCustomerFile(event.target.files[0]);
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };
  const toggleItemForm = () => {
    setIsItemFormOpen(!isItemFormOpen);
    setName(''); // Clear the input when toggling form
  };
  
  const handleCustomerUpload = async () => {
    setCustomerLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      await axios.post(
        'http://82.180.163.36:5000/api/excel/customer',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `Bearer ${localStorage.getItem('token')}`, // Set the authorization header
          },
        }
      );
      alert('Customer data uploaded successfully');
    } catch (error) {
      alert('An error occurred while uploading customer data');
    } finally {
      setCustomerLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name.trim()) {
      alert('Please enter a name.');
      return;
    }

    if (editingId) {
      axios
      .put(
        `http://82.180.163.36:5000/api/customers/${editingId}`,
        { name },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`, // Set the authorization header
          },
        }
      )
        .then((response) => {
          console.log('Customer data updated:', response.data);
          setEditingId('');
          setName('');
          fetchCustomers();
        })
        .catch((error) => {
          console.error('Error updating customer data:', error);
        });
    } else {
      axios
      .post(
        'http://82.180.163.36:5000/api/customers',
        { name },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`, // Set the authorization header
          },
        }
      )
        .then((response) => {
          console.log('New customer saved:', response.data);
          setName('');
          fetchCustomers();
        })
        .catch((error) => {
          console.error('Error saving customer data:', error);
        });
    }
  };

  const handleEdit = (customer) => {
    setEditingId(customer._id);
    setName(customer.name);
    setIsItemFormOpen(true);
  };

  const handleDelete = (id) => {
    axios
      .delete(`http://82.180.163.36:5000/api/customers/${id}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`, // Set the authorization header
        },
      })
      .then(() => {
        console.log('Customer deleted:', id);
        fetchCustomers();
      })
      .catch((error) => {
        console.error('Error deleting customer:', error);
      });
  };
  
  const filteredCustomers = customers.filter((customer) =>
    customer?.name?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  const pageCount = Math.ceil(filteredCustomers.length / ITEMS_PER_PAGE);
  const offset = pageNumber * ITEMS_PER_PAGE;
  const currentPageItems = filteredCustomers.slice(offset, offset + ITEMS_PER_PAGE);
  


  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="customer-container">
       <div className="left-column">
        
      <div className="title-bar">
       <h1 className="customer-heading">Customer</h1></div>
       <div className="search-container">
          <input
            type="text"
            placeholder="Search for Customer Name"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        
        <div className="content">
        {isLoading ? (
          <div className="loader-overlay">
            <BeatLoader color="#3498db" loading={isLoading} size={40} />
          </div>
        ) : (
          <table className="customer-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentPageItems.map((customer) => (
              <tr key={customer._id}>
                <td>{customer.name}</td>
                <td >
                  <button className="edit-button" onClick={() => handleEdit(customer)}>
                    <FontAwesomeIcon icon={faEdit} />
                    Edit</button>
                    { userRole === 'Admin' ?
                 <>  <button className="delete-button" onClick={() => handleDelete(customer._id)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                      Delete
                    </button></>:null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        )}
        </div>
        <div className="pagination-container">
          <ReactPaginate
        previousLabel={<span>&#8592; Prev</span>}
        nextLabel={<span>Next &#8594;</span>}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      />
      </div>
      </div>

 <div className="right-column">
    {/* Add New Customer section */}
    {!isItemFormOpen && (
      <div className="add-customer-section">
       
        <div className="title-bar">
          <label htmlFor="customerFile">Upload Customer Excel File:</label></div>
          <div className="form-row">
          <div className="file-input-container">
            <label htmlFor="file-upload" className="file-upload-label">
              <FontAwesomeIcon icon={faUpload} className="upload-icon" />
              {file ? file.name : 'Choose File'}
            </label>
            <input
              id="file-upload"
              name="customerFile"
              type="file"
              onChange={handleCustomerFileChange}
              className="file-input"
            />
          </div>
          <button style={{width:'30%'}} onClick={handleCustomerUpload}>
            <FontAwesomeIcon icon={faUpload} className="upload-icon" />
            Upload Customer Data
          </button>
        </div>
        <div className="title-bar"> Single New Customer Section</div>
        <div className="form-row">
          <button style={{width:'30%'}} type="button" className="add-button" onClick={toggleItemForm}>
            New Customer
          </button>
        </div>
      </div>
    )}

    {/* Form for adding/editing customers */}
    {isItemFormOpen && (
      <form onSubmit={handleSubmit}>
        {/* Existing form rows */}
        <div className="form-row">
          <label htmlFor="name">Customer Name:</label>
          <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="form-row">
          <button type="submit" className="add-button">
            <FontAwesomeIcon icon={faPlus} className="add-icon" />
            {editingId ? 'Update Customer' : 'Add Customer'}
          </button>
          {/* New back button to hide the form */}
          <button type="button" className="back-button" onClick={toggleItemForm}>
            Back
          </button>
        </div>
      </form>
    )}
     </div>
     
    </div>
  );
};

export default Customer;
