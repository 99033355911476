// src/UserLogin.js
import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';
import { toast } from 'react-toastify';
import LoadingSpinner from "./LoadingSpinner";


const UserLogin = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the login request starts
    try {
      const response = await axios.post("http://82.180.163.36:5000/api/users/login", {
        email,
        password,
      }
    );

      const data = response.data;
      
      const {user,token,} = response.data;
      localStorage.setItem('token', token);
      console.log(user); // Check the response from the server

      if (data) {
        // Call the onLogin function passed as a prop from the parent component (App.js)
        onLogin(user);
        toast.success("Login successfully!"); // Assuming the server returns the username after login
      } else {
        // Handle login failure
        toast.error("Login failed!", user.message);
        console.log("Login failed:", user.message);
      }

      // Reset the form after handling the data
      setEmail("");
      setPassword("");
    } catch (error) {
      toast.error("Login failed! : Please Enter Correct Email and Password");
      console.error("Error:", error);
    } finally {
      setLoading(false); // Set loading to false after the login request is completed
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit}>
        <div className="form-row">
          <label>Email:</label>
          <input type="email" value={email} onChange={handleEmailChange} required />
        </div>
        <div className="form-row">
          <label>Password:</label>
          <input type="password" value={password} onChange={handlePasswordChange} required />
        </div>
        <div className="form-row">
          <label></label>
          <button type="submit">Login</button>
        </div>
        {loading && <LoadingSpinner />} {/* Show the loading spinner when loading is true */}

      </form>
    </div>
  );
};

export default UserLogin;
