import React, { useState } from 'react';
import ItemForm from './ItemForm';
import ItemList from './itemlist';

function ManageItem() {
  const [showForm, setShowForm] = useState(false);

  const handleAddNew = () => {
    setShowForm(true);
  };

  const handleBack = () => {
    setShowForm(false);
  };

  return (
    <div className='main-createinvoice' style={{ paddingLeft: '20%' }}>
      <h1>Item Management</h1>
      {!showForm && <button style={{width:'20%',marginLeft:'38%'}} onClick={handleAddNew}>Add New</button>}
      {showForm ? (
        <>
          <button onClick={handleBack}>Back</button>
          <ItemForm />
        </>
      ) : (
        <>
          <hr />
          <ItemList />
        </>
      )}
    </div>
  );
}

export default ManageItem;
