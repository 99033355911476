import React from 'react';
import { createRoot } from 'react-dom'; // Import createRoot from react-dom
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const root = createRoot(document.getElementById('root')); // Use createRoot here
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

i18n
  .use(initReactI18next)
  .init({
    lng: 'en', // Set the default language here
    fallbackLng: 'en', // Fallback language in case the selected language is not available
    interpolation: {
      escapeValue: false, // React already escapes the values
    },
  });

reportWebVitals();
