import React, { useState, useEffect } from 'react';
import './MainScreen.css'; // Import the CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCog, faUsers, faFileInvoiceDollar, faSignOutAlt,faEdit } from '@fortawesome/free-solid-svg-icons';

import InvoiceList from './InvoiceList';
import Customers from './Customer';
import ManageItem from './ManageItem';
import logo from '../image/test.jpg';
import UserList from './UserList';
import ClassList from './ClassManagement';

function MainScreen({ userRole }) {
  const [activeMenuItem, setActiveMenuItem] = useState('manageInvoice'); // Set the default active menu item


  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/'; // Redirect to the login page
  };

  return (
    <div className="main-screen">
      <div className="sidebar">
        <div className="logo">
          <img src={logo} alt="Logo" />
          <span>Invoice System</span>
        </div>
        <div className="line" />
        {/* White line */}
        <ul className="menu">
          <li
            className={
              activeMenuItem === 'manageInvoice' ? 'active' : ''
            }
            onClick={() => handleMenuItemClick('manageInvoice')}
          >
            <FontAwesomeIcon icon={faFileInvoiceDollar} />
            <span>Manage Invoice</span>
            <ul className="submenu" style={{display:'none'}}>
              <li
                className={
                  activeMenuItem === 'createInvoice' ? 'active' : ''
                }
                onClick={() => handleMenuItemClick('createInvoice')}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>Create New Invoice</span>
              </li>
              <li
                className={
                  activeMenuItem === 'allInvoices' ? 'active' : ''
                }
                onClick={() => handleMenuItemClick('allInvoices')}
              >
                <FontAwesomeIcon icon={faFileInvoiceDollar} />
                <span>All Invoices</span>
              </li>
            </ul>
          </li>
          <li
            className={activeMenuItem === 'manageItem' ? 'active' : ''}
            onClick={() => handleMenuItemClick('manageItem')}
          >
            <FontAwesomeIcon icon={faCog} />
            <span>Manage Item</span>
            <ul className="submenu" style={{display:'none'}}>
              <li
                className={
                  activeMenuItem === 'createItem' ? 'active' : ''
                }
                onClick={() => handleMenuItemClick('createItem')}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>Create New Item</span>
              </li>
              <li
                className={
                  activeMenuItem === 'itemList' ? 'active' : ''
                }
                onClick={() => handleMenuItemClick('itemList')}
              >
                <FontAwesomeIcon icon={faCog} />
                <span>Item List</span>
              </li>
              <li
                className={
                  activeMenuItem === 'uploadByExcel' ? 'active' : ''
                }
                onClick={() => handleMenuItemClick('uploadByExcel')}
              >
                <FontAwesomeIcon icon={faCog} />
                <span>Upload by Excel</span>
              </li>
            </ul>
          </li>
          <li
            className={activeMenuItem === 'manageCustomer' ? 'active' : ''}
            onClick={() => handleMenuItemClick('manageCustomer')}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span>Manage Customer</span>
            <ul className="submenu" style={{display:'none'}}>
              <li
                className={
                  activeMenuItem === 'addNewCustomer' ? 'active' : ''
                }
                onClick={() => handleMenuItemClick('addNewCustomer')}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>Add New Customer</span>
              </li>
              <li
                className={
                  activeMenuItem === 'customerList' ? 'active' : ''
                }
                onClick={() => handleMenuItemClick('customerList')}
              >
                <FontAwesomeIcon icon={faCog} />
                <span>Customer List</span>
              </li>
              <li
                className={
                  activeMenuItem === 'uploadByExcelCustomer' ? 'active' : ''
                }
                onClick={() => handleMenuItemClick('uploadByExcelCustomer')}
              >
                <FontAwesomeIcon icon={faCog} />
                <span>Upload by Excel</span>
              </li>
            </ul>
          </li>
          <li
            className={activeMenuItem === 'classList' ? 'active' : ''}
            onClick={() => handleMenuItemClick('classList')}
            // Add the "has-submenu" class to indicate this item has a submenu
            className={`${
              activeMenuItem === 'classList' ? 'active' : ''
            } has-submenu`}
          >
            <FontAwesomeIcon icon={faEdit} />
            <span>Manage Class</span>
            {/* Submenu for "Manage User" (Only for 'Admin' role) */}
            {userRole === 'Admin' && (
              <ul className="submenu" style={{display:'none'}}>
                <li>
                  <span>User List</span>
                </li>
                <li>
                  <span>Add New User</span>
                </li>
              </ul>
            )}
          </li>
      


          <li
            className={activeMenuItem === 'manageUser' ? 'active' : ''}
            onClick={() => handleMenuItemClick('manageUser')}
            // Add the "has-submenu" class to indicate this item has a submenu
            className={`${
              activeMenuItem === 'manageUser' ? 'active' : ''
            } has-submenu`}
          >
            <FontAwesomeIcon icon={faUsers} />
            <span>Manage User</span>
            {/* Submenu for "Manage User" (Only for 'Admin' role) */}
            {userRole === 'Admin' && (
              <ul className="submenu" style={{display:'none'}}>
                <li>
                  <span>User List</span>
                </li>
                <li>
                  <span>Add New User</span>
                </li>
              </ul>
            )}
          </li>

          
          <li className="logout" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span>Logout</span>
          </li>
        </ul>
      </div>
      <div className="content">
        {/* Conditionally render the corresponding component based on the activeMenuItem */}
        {activeMenuItem === 'manageItem' && <ManageItem />}
        {activeMenuItem === 'manageCustomer' && <Customers />}
        {activeMenuItem === 'manageInvoice' && <InvoiceList />}
        {activeMenuItem === 'classList' && <ClassList />}
        {/* Add other submenu conditions here */}
        {activeMenuItem === 'createInvoice' && (
          <div>Create New Invoice Component</div>
        )}
        {activeMenuItem === 'allInvoices' && (
          <div>All Invoices Component</div>
        )}
        {activeMenuItem === 'createItem' && (
          <div>Create New Item Component</div>
        )}
        {activeMenuItem === 'itemList' && <div>Item List Component</div>}
        {activeMenuItem === 'uploadByExcel' && (
          <div>Upload by Excel Component</div>
        )}
        {activeMenuItem === 'addNewCustomer' && (
          <div>Add New Customer Component</div>
        )}
        {activeMenuItem === 'customerList' && (
          <div>Customer List Component</div>
        )}
        {activeMenuItem === 'uploadByExcelCustomer' && (
          <div>Upload by Excel (Customer) Component</div>
        )}
        {/* ... */}
        {activeMenuItem === 'manageUser' && userRole === 'Admin' && (
          <UserList />
        )}
      </div>
    </div>
  );
}

export default MainScreen;
