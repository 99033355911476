

import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import "./CustomerForm.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faSpinner, faUpload } from '@fortawesome/free-solid-svg-icons';

const  AddItemForm =({handleSaveitem})=>{
  const [formData, setFormData] = useState({
    Item: '',
    Description: '',
    Price: '',
  });

  const [loading, setLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleSaveButtonClick = () => {
    setIsPopupOpen(true);
  };


const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true); 
  if(formData!=null){
  handleSaveitem(formData);
  setIsPopupOpen(false);
  }else {
    alert("Customer name cannot be empty.");
  };
};
const handleInputChange = (e) => {
  setFormData({
    ...formData,
    [e.target.name]: e.target.value,
  });
};
const handleCancelButtonClick = () => {
  setIsPopupOpen(false);
};

  return (
    <>
      <button onClick={handleSaveButtonClick} style={{width:'43%'}}>Add New item</button>
      {isPopupOpen && (
        <div className="popup-container">
             <div className="popup">
    <div className="single-item-form">
      <h2>Add single Item</h2>
        <div className="form-row">
          <label htmlFor="Item">Item:</label>
          <input
            type="text"
            name="Item"
            value={formData.Item}
            onChange={handleInputChange}
            placeholder="Enter item name" />
        </div>
        <div className="form-row">
          <label htmlFor="Description">Description:</label>
          <input
            type="text"
            name="Description"
            value={formData.Description}
            onChange={handleInputChange}
            placeholder="Enter item description" />
        </div>
        <div className="form-row">
          <label htmlFor="Price">Price:</label>
          <input
            type="text"
            name="Price"
            value={formData.Price}
            onChange={handleInputChange}
            placeholder="Enter item price" />
        </div>

        <div className="form-row">
          <button className="save-button" onClick={handleSubmit} type="submit">
            <FontAwesomeIcon size="sm" className="save-icon" icon={faSave} />
            Save Item
          </button>
          
          <button onClick={handleCancelButtonClick}>Cancel</button>
        </div>
    </div></div></div>
)}
    <ToastContainer /></>
  );
};
export default AddItemForm;
