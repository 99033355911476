import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './itemForm.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faSpinner, faUpload } from '@fortawesome/free-solid-svg-icons';

function ItemForm() {
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    Item: '',
    Description: '',
    Price: '',
  });

  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = async () => {
    setLoading(true); 
    const formData = new FormData();
    formData.append('file', file);

    try {
      await axios.post('http://82.180.163.36:5000/api/excel/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      alert('Data uploaded successfully');
    } catch (error) {
      alert('An error occurred while uploading data');
    } finally {
      setLoading(false); 
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 
    try {
      await axios.post('http://82.180.163.36:5000/api/items', formData, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setFormData({
        Item: '',
        Description: '',
        Price: '',
      });
      toast.success('Item saved successfully.');
    } catch (error) {
      toast.error('Error saving item:', error);
      console.error('Error saving item:', error);
    } finally {
      setLoading(false); // Hide the loading indicator whether the save was successful or not
    }
  };

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get('http://82.180.163.36:5000/api/items', {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        // Update the items list in your state or context, e.g., setItems(response.data)
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    };

    fetchItems();
  }, [loading, formData]);

  return (
    
   
    <> 
    
     <div className="item-form-container">
     <div className="title-bar">
        <h2>Multiple Item Upload from Excel</h2>
        <p>Excel Format: Item | Description | Price</p>
      </div>
      <div className="form-row">
        <div className="file-input-container">
          <label htmlFor="file-upload" className="file-upload-label">
            <FontAwesomeIcon icon={faUpload} className="upload-icon" />
            {file ? `${file.length} files selected` : 'Choose File(s)'}
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={handleFileChange}
            className="file-input"
            multiple
          />
        </div>
        <button  onClick={handleUpload} className="upload-button">
          Upload
          {loading && <FontAwesomeIcon icon={faSpinner} spin className="upload-spinner" />}
        </button>
      </div>

      {loading && (
        <div className="loading-spinner-overlay">
          <div className="loading-spinner">
            <FontAwesomeIcon icon={faSpinner} spin />
            Loading...
          </div>
        </div>
      )}
      <div className="single-item-form">
      <h2>Add single Item</h2>
   <form onSubmit={handleSubmit} className="single-item-form">
        <div className="form-row">
          <label htmlFor="Item">Item:</label>
          <input
            type="text"
            name="Item"
            value={formData.Item}
            onChange={handleInputChange}
            placeholder="Enter item name"
          />
        </div>
        <div className="form-row">
          <label htmlFor="Description">Description:</label>
          <input
            type="text"
            name="Description"
            value={formData.Description}
            onChange={handleInputChange}
            placeholder="Enter item description"
          />
        </div>
        <div className="form-row">
          <label htmlFor="Price">Price:</label>
          <input
            type="text"
            name="Price"
            value={formData.Price}
            onChange={handleInputChange}
            placeholder="Enter item price"
          />
        </div>

        <div className="form-row">
          <button className="save-button" type="submit">
            <FontAwesomeIcon size="sm" className="save-icon" icon={faSave} />
            Save Item
          </button>
        </div>
      </form>
      </div>
      <ToastContainer />
      </div>
      </>
  );
}


export default ItemForm;
