import React, { useState } from "react";
import "./CustomerForm.css";

const CustomerForm = ({ handleSaveCustomer }) => {
  const [customerName, setCustomerName] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleSaveButtonClick = () => {
    setIsPopupOpen(true);
  };

  const handleSave = () => {
    if (customerName.trim() !== "") {
      handleSaveCustomer(customerName);
      setIsPopupOpen(false);
      setCustomerName(""); // Clear the customer name after saving
    } else {
      alert("Customer name cannot be empty.");
    }
  };

  const handleCancelButtonClick = () => {
    setIsPopupOpen(false);
  };

  return (
    <div>
      <button onClick={handleSaveButtonClick}>Add New Customer</button>

      {isPopupOpen && (
        <div className="popup-container">
          <div className="popup">
            <h2>Add New </h2>
            <div className="form-row">
              <label>Enter Customer Name:</label>
            <input
              type="text"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              placeholder="Customer Name"
            /></div>
            <div className="form-row">
            <button onClick={handleSave}>Save</button>
            <button onClick={handleCancelButtonClick}>Cancel</button>
          </div></div>
        </div>
      )}
    </div>
  );
};

export default CustomerForm;
